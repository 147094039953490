/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { QueryKey } from '@tanstack/react-query'
import { useApiMutation, useApiQuery } from '@digital-magic/react-common/lib/api/hooks'
import { OptionalType } from '@digital-magic/ts-common-utils/lib/type'
import { apiBaseUrl } from '@constants/configuration'
import { ApiMutationOpts, ApiQueryOpts } from '@api/types'
import { callOnly, receiveOnly, sendAndReceive, sendOnly } from '@api/utils'
import { IdObject } from '@api/endpoints/types'
import { UpdateImagesOrderRequest } from '@api/endpoints/buildings'
import { queryKeys as layoutTypeQueryKeys } from '@api/endpoints/buildings/layoutTypes'
import { FileId } from '@api/endpoints/files'
import {
  AddressGroup,
  BookingItemView,
  BookingItems,
  Building,
  BuildingAddress,
  BuildingAddressList,
  BuildingId,
  BuildingList,
  CreateBuildingRequest,
  CreateHouseOptionValueExceptionRequest,
  CreateHousePlanImageRequest,
  CreateHouseRequest,
  CreateHouseStyleExceptionRequest,
  House,
  HouseId,
  HouseList,
  HouseOptionValueExceptionId,
  HouseOptionValueExceptions,
  HousePlanImages,
  HouseStyleExceptionId,
  HouseStyleExceptions,
  UpdateBuildingRequest,
  UpdateHouseOptionValueExceptionRequest,
  UpdateHousePlanImageVisibilityRequest,
  UpdateHouseRequest,
  UpdateHouseStatusRequest
} from '.'

const addressesUrl = `${apiBaseUrl}/addresses`
const addressGroupUrl = (group: AddressGroup): string => `${addressesUrl}/${group}`

const buildingsUrl = `${apiBaseUrl}/buildings`
const buildingUrl = (buildingId: BuildingId): string => `${buildingsUrl}/${buildingId}`
const buildingHousesUrl = (buildingId: BuildingId): string => `${buildingUrl(buildingId)}/houses`

const housesUrl = `${apiBaseUrl}/houses`
const houseUrl = (houseId: HouseId): string => `${housesUrl}/${houseId}`
const houseStatusUrl = (houseId: HouseId): string => `${houseUrl(houseId)}/status`
const houseImagesUrl = (houseId: HouseId): string => `${houseUrl(houseId)}/images`
const houseImagesOrderUrl = (houseId: HouseId): string => `${houseImagesUrl(houseId)}/order`
const houseImageUrl = (houseId: HouseId, imageId: FileId): string => `${houseImagesUrl(houseId)}/${imageId}`

const houseImageVisibilityUrl = (houseId: HouseId, imageId: FileId): string =>
  `${houseImageUrl(houseId, imageId)}/visibility`

const houseStyleExceptions = (houseId: HouseId): string => `${houseUrl(houseId)}/style-exceptions`
const houseStyleException = (houseId: HouseId, exceptionId: HouseStyleExceptionId): string =>
  `${houseStyleExceptions(houseId)}/${exceptionId}`

const houseOptionValueExceptions = (houseId: HouseId): string => `${houseUrl(houseId)}/option-value-exceptions`
const houseOptionValueException = (houseId: HouseId, exceptionId: HouseOptionValueExceptionId): string =>
  `${houseOptionValueExceptions(houseId)}/${exceptionId}`

const bookingItemsUrl = `${apiBaseUrl}/booking-items`
const bookingItemUrl = (houseId: HouseId): string => `${bookingItemsUrl}/${houseId}`

export const queryKeys = {
  getAddressesAll: ['getAddresses'],
  getAddresses: (group: AddressGroup): QueryKey => [...queryKeys.getAddressesAll, group],

  getBuildingsAll: ['getBuildings'],
  getBuildings: (address: BuildingAddress): QueryKey => [...queryKeys.getBuildingsAll, address],
  getBuildingAny: ['getBuilding'],
  getBuilding: (buildingId: BuildingId): QueryKey => [...queryKeys.getBuildingAny, buildingId],

  getBuildingHouses: (buildingId: BuildingId) => ['getBuildingHouses', buildingId],

  getHousesAll: ['getHouses'],
  getHouses: (address: BuildingAddress): QueryKey => [...queryKeys.getHousesAll, address],
  getHouseAny: ['getHouse'],
  getHouse: (houseId: HouseId): QueryKey => [...queryKeys.getHouseAny, houseId],

  getHousePlanImagesAll: ['getHousePlanImages'],
  getHousePlanImages: (houseId: HouseId): QueryKey => [...queryKeys.getHousePlanImagesAll, houseId],

  getHouseStyleExceptionsAll: ['getHouseStyleExceptions'],
  getHouseStyleExceptions: (houseId: HouseId): QueryKey => [...queryKeys.getHouseStyleExceptionsAll, houseId],

  getHouseOptionValueExceptionsAll: ['getHouseOptionValueExceptions'],
  getHouseOptionValueExceptions: (houseId: HouseId): QueryKey => [
    ...queryKeys.getHouseOptionValueExceptionsAll,
    houseId
  ],

  getBookingItems: ['getBookingItems'],
  getBookingItem: (houseId: HouseId): QueryKey => ['getBookingItem', houseId]
}

export const useGetAddresses = (group: AddressGroup, opts?: ApiQueryOpts<BuildingAddressList>) =>
  useApiQuery({
    queryFn: () =>
      receiveOnly({
        method: 'get',
        url: addressGroupUrl(group),
        responseSchema: BuildingAddressList
      }),
    queryKey: queryKeys.getAddresses(group),
    ...opts
  })

export const useGetBuildings = (address: OptionalType<BuildingAddress>, opts?: ApiQueryOpts<BuildingList>) =>
  useApiQuery({
    queryFn: () =>
      receiveOnly({
        method: 'get',
        url: buildingsUrl,
        params: { address },
        responseSchema: BuildingList
      }),
    // TODO: Is it a problem?
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: address ? queryKeys.getBuildings(address) : queryKeys.getBuildingsAll,
    ...opts
  })

type WithBuildingId = {
  id: BuildingId
}

type CreateBuildingRequestWithParams = CreateBuildingRequest & {
  skipAddressValidation?: OptionalType<boolean>
}

export const useCreateBuilding = (opts?: ApiMutationOpts<IdObject, CreateBuildingRequestWithParams>) =>
  useApiMutation({
    mutationFn: ({ skipAddressValidation, ...data }) =>
      sendAndReceive({
        method: 'post',
        url: buildingsUrl,
        requestSchema: CreateBuildingRequest,
        responseSchema: IdObject,
        params: { skipAddressValidation: skipAddressValidation ? skipAddressValidation : undefined },
        data
      }),
    invalidateQueries: [
      queryKeys.getBuildingsAll,
      layoutTypeQueryKeys.getLayoutTypeAny,
      queryKeys.getAddresses('available')
    ],
    ...opts
  })

type UpdateBuildingRequestWithParams = UpdateBuildingRequest & WithBuildingId

export const useUpdateBuilding = (opts?: ApiMutationOpts<void, UpdateBuildingRequestWithParams>) =>
  useApiMutation({
    mutationFn: ({ id, ...data }) =>
      sendOnly({
        method: 'put',
        url: buildingUrl(id),
        requestSchema: UpdateBuildingRequest,
        data
      }),
    invalidateQueries: [queryKeys.getBuildingsAll],
    ...opts
  })

export const useGetBuilding = (buildingId: BuildingId, opts?: ApiQueryOpts<Building>) =>
  useApiQuery({
    queryFn: () =>
      receiveOnly({
        method: 'get',
        url: buildingUrl(buildingId),
        responseSchema: Building
      }),
    queryKey: queryKeys.getBuilding(buildingId),
    ...opts
  })

export const useDeleteBuilding = (opts?: ApiMutationOpts<void, BuildingId>) =>
  useApiMutation({
    mutationFn: (buildingId) =>
      callOnly({
        method: 'delete',
        url: buildingUrl(buildingId)
      }),
    invalidateQueries: [
      queryKeys.getBuildingsAll,
      layoutTypeQueryKeys.getLayoutTypeAny,
      queryKeys.getAddresses('available')
    ],
    ...opts
  })

type WithHouseId = {
  id: HouseId
}

export const useGetHouses = (address: OptionalType<BuildingAddress>, opts?: ApiQueryOpts<HouseList>) =>
  useApiQuery({
    queryFn: () =>
      receiveOnly({
        method: 'get',
        url: housesUrl,
        params: { address },
        responseSchema: HouseList
      }),
    // TODO: Is it a problem?
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: address ? queryKeys.getHouses(address) : queryKeys.getHousesAll,
    ...opts
  })

export const useCreateHouse = (opts?: ApiMutationOpts<IdObject, CreateHouseRequest>) =>
  useApiMutation({
    mutationFn: (data) =>
      sendAndReceive({
        method: 'post',
        url: housesUrl,
        requestSchema: CreateHouseRequest,
        responseSchema: IdObject,
        data
      }),
    invalidateQueries: [queryKeys.getHousesAll, queryKeys.getBuildingAny],
    ...opts
  })

type UpdateHouseRequestWithParams = UpdateHouseRequest & WithHouseId

export const useUpdateHouse = (opts?: ApiMutationOpts<void, UpdateHouseRequestWithParams>) =>
  useApiMutation({
    mutationFn: ({ id, ...data }) =>
      sendOnly({
        method: 'put',
        url: houseUrl(id),
        requestSchema: UpdateHouseRequest,
        data
      }),
    invalidateQueries: [queryKeys.getHousesAll],
    ...opts
  })

export const useGetHouse = (houseId: HouseId, opts?: ApiQueryOpts<House>) =>
  useApiQuery({
    queryFn: () =>
      receiveOnly({
        method: 'get',
        url: houseUrl(houseId),
        responseSchema: House
      }),
    queryKey: queryKeys.getHouse(houseId),
    ...opts
  })

export const useGetBuildingHouses = (buildingId: BuildingId, opts?: ApiQueryOpts<HouseList>) =>
  useApiQuery({
    queryFn: () =>
      receiveOnly({
        method: 'get',
        url: buildingHousesUrl(buildingId),
        responseSchema: HouseList
      }),
    queryKey: queryKeys.getBuildingHouses(buildingId),
    ...opts
  })

type UpdateHouseStatusRequestWithParams = UpdateHouseStatusRequest & WithHouseId

export const useUpdateHouseStatus = (opts?: ApiMutationOpts<void, UpdateHouseStatusRequestWithParams>) =>
  useApiMutation({
    mutationFn: ({ id, ...data }) =>
      sendOnly({
        method: 'put',
        url: houseStatusUrl(id),
        requestSchema: UpdateHouseStatusRequest,
        data
      }),
    invalidateQueries: [queryKeys.getHousesAll],
    ...opts
  })

export const useDeleteHouse = (opts?: ApiMutationOpts<void, HouseId>) =>
  useApiMutation({
    mutationFn: (houseId) =>
      callOnly({
        method: 'delete',
        url: houseUrl(houseId)
      }),
    invalidateQueries: [queryKeys.getHousesAll, queryKeys.getBuildingAny],
    ...opts
  })

export const useGetHousePlanImages = (houseId: HouseId, opts?: ApiQueryOpts<HousePlanImages>) =>
  useApiQuery({
    queryFn: () =>
      receiveOnly({
        method: 'get',
        url: houseImagesUrl(houseId),
        responseSchema: HousePlanImages
      }),
    queryKey: queryKeys.getHousePlanImages(houseId),
    ...opts
  })

type WithHouseIdParam = {
  houseId: HouseId
}
type CreateHousePlanImageRequestWithParams = CreateHousePlanImageRequest & WithHouseIdParam

export const useCreateHousePlanImage = (opts?: ApiMutationOpts<IdObject, CreateHousePlanImageRequestWithParams>) =>
  useApiMutation({
    mutationFn: ({ houseId, ...data }) =>
      sendAndReceive({
        method: 'post',
        url: houseImagesUrl(houseId),
        requestSchema: CreateHousePlanImageRequest,
        responseSchema: IdObject,
        data
      }),
    invalidateQueries: [queryKeys.getHousePlanImagesAll],
    ...opts
  })

type WithHouseIdAndFileIdParams = WithHouseIdParam & {
  fileId: FileId
}

type UpdateHousePlanImageVisibilityRequestWithParams = UpdateHousePlanImageVisibilityRequest &
  WithHouseIdAndFileIdParams

export const useUpdateHousePlanImageVisibility = (
  opts?: ApiMutationOpts<void, UpdateHousePlanImageVisibilityRequestWithParams>
) =>
  useApiMutation({
    mutationFn: ({ houseId, fileId, ...data }) =>
      sendOnly({
        method: 'put',
        url: houseImageVisibilityUrl(houseId, fileId),
        requestSchema: UpdateHousePlanImageVisibilityRequest,
        data
      }),
    invalidateQueries: [queryKeys.getHousePlanImagesAll],
    ...opts
  })

type UpdateHousePlanImagesOrderRequest = UpdateImagesOrderRequest & WithHouseIdParam

export const useUpdateHousePlanImagesOrder = (opts?: ApiMutationOpts<void, UpdateHousePlanImagesOrderRequest>) =>
  useApiMutation({
    mutationFn: ({ houseId, ...data }) =>
      sendOnly({
        method: 'put',
        url: houseImagesOrderUrl(houseId),
        requestSchema: UpdateImagesOrderRequest,
        data
      }),
    invalidateQueries: [queryKeys.getHousePlanImagesAll],
    ...opts
  })

export const useDeleteHousePlanImage = (opts?: ApiMutationOpts<void, WithHouseIdAndFileIdParams>) =>
  useApiMutation({
    mutationFn: ({ houseId, fileId }) =>
      callOnly({
        method: 'delete',
        url: houseImageUrl(houseId, fileId)
      }),
    invalidateQueries: [queryKeys.getHousePlanImagesAll],
    ...opts
  })

export const useGetHouseStyleExceptions = (houseId: HouseId, opts?: ApiQueryOpts<HouseStyleExceptions>) =>
  useApiQuery({
    queryFn: () =>
      receiveOnly({
        method: 'get',
        url: houseStyleExceptions(houseId),
        responseSchema: HouseStyleExceptions
      }),
    queryKey: queryKeys.getHouseStyleExceptions(houseId),
    ...opts
  })

type CreateHouseStyleExceptionRequestWithParams = CreateHouseStyleExceptionRequest & WithHouseIdParam

export const useCreateHouseStyleException = (
  opts?: ApiMutationOpts<IdObject, CreateHouseStyleExceptionRequestWithParams>
) =>
  useApiMutation({
    mutationFn: ({ houseId, ...data }) =>
      sendAndReceive({
        method: 'post',
        url: houseStyleExceptions(houseId),
        requestSchema: CreateHouseStyleExceptionRequest,
        responseSchema: IdObject,
        data
      }),
    invalidateQueries: [queryKeys.getHouseStyleExceptionsAll],
    ...opts
  })

type DeleteHouseStyleExceptionRequest = WithHouseIdParam & {
  houseStyleExceptionId: HouseStyleExceptionId
}

export const useDeleteHouseStyleException = (opts?: ApiMutationOpts<void, DeleteHouseStyleExceptionRequest>) =>
  useApiMutation({
    mutationFn: ({ houseId, houseStyleExceptionId }) =>
      callOnly({
        method: 'delete',
        url: houseStyleException(houseId, houseStyleExceptionId)
      }),
    invalidateQueries: [queryKeys.getHouseStyleExceptionsAll],
    ...opts
  })

export const useGetHouseOptionValueExceptions = (houseId: HouseId, opts?: ApiQueryOpts<HouseOptionValueExceptions>) =>
  useApiQuery({
    queryFn: () =>
      receiveOnly({
        method: 'get',
        url: houseOptionValueExceptions(houseId),
        responseSchema: HouseOptionValueExceptions
      }),
    queryKey: queryKeys.getHouseOptionValueExceptions(houseId),
    ...opts
  })

type CreateHouseOptionValueExceptionRequestWithParams = CreateHouseOptionValueExceptionRequest & WithHouseIdParam

export const useCreateHouseOptionValueException = (
  opts?: ApiMutationOpts<IdObject, CreateHouseOptionValueExceptionRequestWithParams>
) =>
  useApiMutation({
    mutationFn: ({ houseId, ...data }) =>
      sendAndReceive({
        method: 'post',
        url: houseOptionValueExceptions(houseId),
        requestSchema: CreateHouseOptionValueExceptionRequest,
        responseSchema: IdObject,
        data
      }),
    invalidateQueries: [queryKeys.getHouseOptionValueExceptionsAll],
    ...opts
  })

type WithHouseIdAndHouseOptionValueExceptionIdParams = WithHouseIdParam & {
  houseOptionValueExceptionId: HouseOptionValueExceptionId
}

type UpdateHouseOptionValueExceptionRequestWithParams = UpdateHouseOptionValueExceptionRequest &
  WithHouseIdAndHouseOptionValueExceptionIdParams

export const useUpdateHouseOptionValueException = (
  opts?: ApiMutationOpts<void, UpdateHouseOptionValueExceptionRequestWithParams>
) =>
  useApiMutation({
    mutationFn: ({ houseId, houseOptionValueExceptionId, ...data }) =>
      sendOnly({
        method: 'put',
        url: houseOptionValueException(houseId, houseOptionValueExceptionId),
        requestSchema: UpdateHouseOptionValueExceptionRequest,
        data
      }),
    invalidateQueries: [queryKeys.getHouseOptionValueExceptionsAll],
    ...opts
  })

export const useDeleteHouseOptionValueException = (
  opts?: ApiMutationOpts<void, WithHouseIdAndHouseOptionValueExceptionIdParams>
) =>
  useApiMutation({
    mutationFn: ({ houseId, houseOptionValueExceptionId }) =>
      callOnly({
        method: 'delete',
        url: houseOptionValueException(houseId, houseOptionValueExceptionId)
      }),
    invalidateQueries: [queryKeys.getHouseOptionValueExceptionsAll],
    ...opts
  })

export const useGetBookingItems = (opts?: ApiQueryOpts<BookingItems>) =>
  useApiQuery({
    queryFn: () =>
      receiveOnly({
        method: 'get',
        url: bookingItemsUrl,
        responseSchema: BookingItems
      }),
    queryKey: queryKeys.getBookingItems,
    ...opts
  })

export const useGetBookingItem = (houseId: HouseId, opts?: ApiQueryOpts<BookingItemView>) =>
  useApiQuery({
    queryFn: () =>
      receiveOnly({
        method: 'get',
        url: bookingItemUrl(houseId),
        responseSchema: BookingItemView
      }),
    queryKey: queryKeys.getBookingItem(houseId),
    ...opts
  })
