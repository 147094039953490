import * as Ord from 'fp-ts/Ord'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/ReadonlyArray'
import * as S from 'fp-ts/string'
import * as M from 'fp-ts/Monoid'
import { flow, pipe } from 'fp-ts/function'
import { sortImageFileEntityByOrderByOrderNumber } from '@api/endpoints/files'
import { BookingItem, HouseStatus, HouseStyleView } from '.'

// utils

export const ordBuildingAddress = S.Ord
export const ordHouseApartmentNumber = S.Ord
export const ordHouseOptionalApartmentNumber = O.getOrd(ordHouseApartmentNumber)

export const ordBookingItemByAddress = pipe(
  ordBuildingAddress,
  Ord.contramap((o: BookingItem) => o.address)
)

export const ordBookingItemByApartmentNumber = pipe(
  ordHouseOptionalApartmentNumber,
  Ord.contramap((o: BookingItem) => pipe(o.apartmentNumber, O.fromNullable))
)

export const ordBookingItemByAddressAndApartmentNumber = M.concatAll(Ord.getMonoid<BookingItem>())([
  ordBookingItemByAddress,
  ordBookingItemByApartmentNumber
])

export const sortHouseStyleImagesByOrderByOrderNumber = A.map((style: HouseStyleView) => ({
  ...style,
  images: sortImageFileEntityByOrderByOrderNumber(style.images).concat()
}))

export const sortBookingItemsByAddressAndApartmentNumber = A.sort(ordBookingItemByAddressAndApartmentNumber)

// TODO: This filtration must be done on server-side
export const sortBookingItemsByAddressAndApartmentNumberAndFilterDisabled = flow(
  sortBookingItemsByAddressAndApartmentNumber,
  A.filter((v) => v.status !== HouseStatus.enum.Disabled)
)
